import { NextRouter } from 'next/router';
import { get } from '../networking';
import { SubscriptionState } from './stripe';
import * as SHA1 from 'crypto-js/sha1';
import { FlowType } from '../components/start/flow-type.enum';
import { GetServerSidePropsContext, GetServerSidePropsResult } from 'next';
import analytics from '../lib/analytics';
import { getCurrentWeekId, getWeekIdForDay } from '../utils/dateHelpers';
import { AppUserAgent } from '../components/core/use-app-user-agent';
import { interpretUserPaymentEntityState } from './payments-gateway';

export const activeFormId = process.env.NEXT_PUBLIC_TYPEFORM_FORM_ID || 'HJmDjOmw';

export async function refreshUserAndStoreIn(session) {
    const userData = await get('users/current-user');
    session.setUser(userData);
    localStorage.setItem('userId', userData.id);
    if (userData.id) {
        analytics.identify(userData.id);
    }
    window && window['ire'] && window['ire']('identify', {
        customerId: userData.id,
        customerEmail: SHA1(userData.email)
    });
    return userData;
}

export async function redirectUserAfterLogin(
    user,
    router: NextRouter,
    redirectUrl?: string | string[],
    formId: string = activeFormId,
    setHasAccess?: (hasAccess: boolean) => void,
    cookies?: { [key: string]: any },
    appUserAgent?: AppUserAgent
) {
    const defaultRedirectFlow = async () => {
        const isUserFirstWeek = getWeekIdForDay(user.createdAt) === getCurrentWeekId(user.timeZone);
        if (user.id) {
            analytics.identify(user.id);
        }
        if (redirectUrl && !formId.startsWith('ss_')) {
            if (cookies?.ss_app === 'true' || appUserAgent?.isApp) {
                router.push('/app-bridge');
                return;
            }
            router.push(redirectUrl as string);
            return;
        } else if (user.diagnosticInFlight) {
            const userData = await get('users/current-user');
            const serverFormId = userData.formId;
            /**
             * If user has phone but diagnostic is inFlight then they have reached phone verification
             * but dropped off which means they have 'completed' the diagnostic. This way we don't force
             * them back through diagnostic to get where they dropped off.
             */
            if (user.phone && formId.startsWith('ss_')) {
                if (serverFormId === 'ss_20240708' || formId === 'ss_20240708') router.push('/diagnostic/forms/ss_20240708/43');
                else if (serverFormId === 'ss_20240909-1' || formId === 'ss_20240909-1') router.push('/diagnostic/forms/ss_20240909-1/43');
                else if (serverFormId === 'ss_20240909-2' || formId === 'ss_20240909-2') router.push('/diagnostic/forms/ss_20240909-2/42');
                else if (serverFormId === 'ss_20240909-3' || formId === 'ss_20240909-3') router.push('/diagnostic/forms/ss_20240909-3/37');
                else if (serverFormId === 'ss_20240909-4' || formId === 'ss_20240909-4') router.push('/diagnostic/forms/ss_20240909-4/37');
                return;
            } else if (formId.startsWith('ss_')) {
                if (serverFormId === 'ss_20240708' || formId === 'ss_20240708') router.push('/diagnostic/forms/ss_20240708/0');
                else if (serverFormId === 'ss_20240909-1' || formId === 'ss_20240909-1') router.push('/diagnostic/forms/ss_20240909-1/0');
                else if (serverFormId === 'ss_20240909-2' || formId === 'ss_20240909-2') router.push('/diagnostic/forms/ss_20240909-2/0');
                else if (serverFormId === 'ss_20240909-3' || formId === 'ss_20240909-3') router.push('/diagnostic/forms/ss_20240909-3/0');
                else if (serverFormId === 'ss_20240909-4' || formId === 'ss_20240909-4') router.push('/diagnostic/forms/ss_20240909-4/0');
                return;
            }
            if (user.phone && serverFormId) {
                router.push('/register/phone');
                return;
            }
            serverFormId ? router.push(`/diagnostic/${serverFormId}/intro`) : router.push(`/diagnostic/${formId}/intro`);
            return;
        } else if (!user.diagnosticInFlight) {
            if (!user.phoneAuthorized) {
                if (formId.startsWith('ss_')) {
                    if (formId === 'ss_20240708') router.push('/diagnostic/forms/ss_20240708/43');
                    else if (formId === 'ss_20240909-1') router.push('/diagnostic/forms/ss_20240909-1/43');
                    else if (formId === 'ss_20240909-2') router.push('/diagnostic/forms/ss_20240909-2/42');
                    else if (formId === 'ss_20240909-3') router.push('/diagnostic/forms/ss_20240909-3/37');
                    else if (formId === 'ss_20240909-4') router.push('/diagnostic/forms/ss_20240909-4/37');
                    return;
                }
                router.push('/register/phone');
                return;
            }
            const userPaymentEntity = await get('payments-gateway/my-payment-entity');
            if(!userPaymentEntity || (!userPaymentEntity.paymentProcessor && !userPaymentEntity.paymentMethodStatus)) {
                if (cookies?.ss_app === 'true' || appUserAgent?.isApp) {
                    if (formId.startsWith('ss_')) {
                        router.push('/app-bridge');
                        return;
                    }
                    router.push({
                        pathname: '/diagnostic/target',
                        query: { redirect: '/app-bridge' }
                    });
                    return;
                }
                if (formId.startsWith('ss_')) {
                    if (formId === 'ss_20240708') router.push('/diagnostic/forms/ss_20240708/52');
                    else if (formId === 'ss_20240909-1') router.push('/diagnostic/forms/ss_20240909-1/52');
                    else if (formId === 'ss_20240909-2') router.push('/diagnostic/forms/ss_20240909-2/51');
                    else if (formId === 'ss_20240909-3') router.push('/diagnostic/forms/ss_20240909-3/49');
                    else if (formId === 'ss_20240909-4') router.push('/diagnostic/forms/ss_20240909-4/49');
                }
                router.push('/diagnostic/target');
                return;
            }
    
            const subscriptionState: SubscriptionState = await interpretUserPaymentEntityState(user, userPaymentEntity);
            switch(subscriptionState) {
            case SubscriptionState.UNKNOWN:
                if (cookies?.ss_app === 'true' || appUserAgent?.isApp) {
                    if (formId.startsWith('ss_')) {
                        router.push('/app-bridge');
                        return;
                    }
                    router.push({
                        pathname: '/diagnostic/target',
                        query: { redirect: '/app-bridge' }
                    });
                    break;
                }
                if (formId.startsWith('ss_')) {
                    if (formId === 'ss_20240708') router.push('/diagnostic/forms/ss_20240708/52');
                    else if (formId === 'ss_20240909-1') router.push('/diagnostic/forms/ss_20240909-1/52');
                    else if (formId === 'ss_20240909-2') router.push('/diagnostic/forms/ss_20240909-2/51');
                    else if (formId === 'ss_20240909-3') router.push('/diagnostic/forms/ss_20240909-3/49');
                    else if (formId === 'ss_20240909-4') router.push('/diagnostic/forms/ss_20240909-4/49');
                    return;
                }
                router.push('/diagnostic/target');
                break;
            case SubscriptionState.NO_CREDIT_CARD_TRIAL:
            case SubscriptionState.CREDIT_CARD_TRIAL:
            case SubscriptionState.ACTIVE:
            case SubscriptionState.ACTIVE_WITH_PAYMENT_ERROR:
            case SubscriptionState.CANCELED:
            default: {
                if (cookies?.ss_app === 'true' || appUserAgent?.isApp) {
                    router.push('/app-bridge');
                    break;
                }
                router.push(isUserFirstWeek ? '/dashboard/tracking' : '/dashboard/progress');
                break;
            }
            }
        }
        if (cookies?.ss_app === 'true' || appUserAgent?.isApp) {
            router.push('/app-bridge?redirect=LoginStackNav');
            return;
        }
        router.push(isUserFirstWeek ? '/dashboard/tracking' : '/dashboard/progress');
        return;
    };

    switch (user.flowType) {
    // ptcc -> Phone to credit card trial users
    case FlowType.PTCC: {
        // Give user access to checkout paths with type 'ptcc'
        if (user.diagnosticInFlight && !user.diagnosticInFlightWithSub && !formId.startsWith('ss_')) {
            const planLinks = ['/subscription/recommended', '/subscription/plans', '/subscription_old/checkout'];
            if (planLinks.includes(router.pathname)) {
                setHasAccess(true);
            } else {
                router.push('/subscription/recommended');
            }
        } else if (user.diagnosticInFlightWithSub) {
            if (router.pathname === '/start/profile') {
                setHasAccess(true);
            } else {
                // User has sub but has not finished diagnostic redirect them to the 'bare-bones' profile page
                router.push('/start/profile');
            }
        } else {
            defaultRedirectFlow();
        }
        break;
    }
    default: defaultRedirectFlow();
    }

}

/**
 *
 * @param context serverSidePropsContext
 * @param response api response (should be with a jwt protected endpoint)
 * @param serverSideResponse normal serverSideResponse
 * @returns server side redirect if user is unauthorized (redirects to login url with redirect param)
 *     or returns normal serverSideResponse
 */
export async function serverSidePropsAuthResponse(
    context: GetServerSidePropsContext,
    response: any,
    serverSideResponse: GetServerSidePropsResult<any>
): Promise<GetServerSidePropsResult<any>> {
    if (response.statusCode === 401) {
        return {
            redirect: {
                permanent: false,
                destination: '/login/phone?redirectUrl=' + encodeURIComponent(context.resolvedUrl)
            },
            props: {}
        };
    }
    return serverSideResponse;
}
